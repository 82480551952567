<template>
  <OneColumn style="background: #19173B; overflow: auto;">
    <template v-slot:ColumnContent>
      <div class="eduPage">
        <div class="container">
          <div class="header">
            <span class="OctaS_PageTitleDefault">Направления обучения</span>
          </div>
          <div class="cards">
            <DirectionCard
              v-for="direction in getNotArchieved"
              :key="direction.id"
              :direction="direction"
              :user="user"
            >
            </DirectionCard>
          </div>
        </div>
      </div>
    </template>
  </OneColumn>
</template>

<script>
import OneColumn from "@/components/ProfileTemplates/OneColumn.vue";

import { mapGetters } from "vuex";
import { getSectors } from "@/api/competence.js";
import DirectionCard from "@/components/Edu/DirectionCard.vue";
export default {
  components: { DirectionCard, OneColumn },
  name: "Edu",
  data() {
    return {
      directions: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getNotArchieved() {
      return this.directions
        .map((x) => {
          let filtered_sections = x.sections.filter(
            (sect) => sect.archieve === false
          );

          return { ...x, sections: filtered_sections };
        })
        .filter((x) => x.sections != null && x.sections.length != 0);
    },
  },

  created() {
    getSectors().then((response) => {
      this.directions = response.data;
    });
  },
};
</script>

<style scoped>
.cards:deep(.directionCard > .el-card) {
  background-color: rgba(32, 30, 72, 0.7) !important;
  border-radius: var(--o-s-global-border-radius) !important;

  box-shadow: 8px 0 8px rgba(31, 18, 35, 0.15) !important;
  border: none;
}
.cards:deep(.directionCard > .el-card .card-header) {
  color: #e8e7ec !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
}
.cards:deep(.directionCard > .el-card .sectionName) {
  color: rgb(200, 195, 222) !important;
  font-weight: 400;
}
.cards:deep(.directionCard > .el-card > .el-card__header) {
  border-color: rgba(232, 231, 236, 0.2);
}

.cards:deep(.directionCard
    .el-card__body
    .section-item
    a
    .right-side
    > div:first-child) {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}

.cards:deep(.directionCard .progress) {
  background-color: #312e57;
}

.cards:deep(.directionCard .el-progress),
.cards:deep(.directionCard .el-progress-bar),
.cards:deep(.directionCard .el-progress-bar__outer) {
  height: 100%;
}

.cards:deep(.directionCard .el-progress-bar__outer) {
  background-color: #312e57;
  height: 100% !important;
}

.cards:deep(.directionCard .progressText) {
  color: #fff !important;
}

h3 {
  color: black !important;
}
.cards {
  width: 100%;
}
.header {
  margin-bottom: 30px;
  font-weight: 600;
  color: black;
}

.container {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}
.eduPage {
  position: relative;
  width: 100%;

  min-height: 100vh;
  height: fit-content;

  font-family: "Montserrat";
  z-index: 0;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .eduPage > .container {
    width: 100%;
  }

  .cards:deep(.directionCard .progress) {
    height: 17px !important;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .eduPage > .container {
    width: 90%;
  }

  .cards:deep(.directionCard .progress) {
    height: 17px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .card-body > .section-item .sectionName {
    font-size: 1rem !important;
  }
}

@media (max-width: 992px) {
  .eduPage {
    padding-bottom: 100px;
  }
}
</style>
