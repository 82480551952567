<template>
  <div class="chart-container">
    <chart
      ref="competenceChart"
      class="text-center m-b-20"
      height="100%"
      width="100%"
      :series="options"
      :text="name"
      @select-cell="selectCell"
    />
  </div>
</template>

<script>
import Chart from "@/components/Competence/CompetenceProject";
import { getUser, getTemplate } from "@/api/competence.js";
export default {
  name: "CompetenceProject",
  components: { Chart },
  data() {
    return {
      department_id: 1,
      selectType: 1,
      countSection: [],
      countSector: [],
      levels: {},
      firstDegree: 47,
      secondDegree: 75,
      radiusWhiteArea: 19,
      options: [],
      skill_id: null,
      test: null,
      name: "",
    };
  },
  props: {
    levelshow: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  watch: {
    levels: {
      handler(val) {
        let ans = [];
        for (let key in this.levels) {
          ans = ans.concat(this.levels[key]);
        }
        this.$emit("select-level", ans);
      },
      deep: true,
    },
  },
  created() {
    getTemplate().then((response) => {
      this.test = response.data;
      this.name = "";
      // this.name = response.data.surname + ' ' + response.data.name
      console.log(response);
      this.test.sectors.sort((a, b) => a.id - b.id);
      for (let i = 0; i < this.test.sectors.length; i++) {
        for (let j = 0; j < this.test.sectors[i].sections.length; j++) {
          this.test.sectors[i].color =
            this.test.sectors[i].color.slice(0, -2) + "3)";
          this.addSectionWithId(
            this.test.sectors[i].id,
            this.test.sectors[i].color,
            this.test.sectors[i].sections[j],
            this.test.sectors[i].name,
            j < 1 ? true : false
          );
        }
        this.countSector.push(this.test.sectors[i].id);
      }
      for (let i = 0; i < this.options.length; i++) {
        if (this.levelshow.includes(this.options[i].custom.level_id)) {
          this.options[i].data[0].color =
            this.options[i].data[0].color.slice(0, -2) + "8)";
          if (this.options[i].custom.section_id.toString() in this.levels) {
            this.levels[this.options[i].custom.section_id.toString()].push(
              this.options[i].custom.level_id
            );
          } else {
            this.levels[this.options[i].custom.section_id.toString()] = [
              this.options[i].custom.level_id,
            ];
          }
        }
      }
      this.skill_id = department.id;
    });
  },
  methods: {
    selectCell(cell) {
      if (cell.section_id.toString() in this.levels) {
        delete this.levels[cell.section_id.toString()];
        this.options.forEach((element) => {
          if (element.custom.section_id === cell.section_id) {
            element.data.forEach((item) => {
              item.color = item.color.slice(0, -2) + "3)";
            });
          }
        });
      } else {
        this.options.forEach((element) => {
          if (element.custom.section_id === cell.section_id) {
            if (cell.levels.includes(element.custom.level_id)) {
              element.data.forEach((item) => {
                item.color = item.color.slice(0, -2) + "8)";
              });
            } else {
              element.data.forEach((item) => {
                item.color = item.color.slice(0, -2) + "3)";
              });
            }
          }
        });
        this.levels[cell.section_id.toString()] = cell.levels;
      }
    },
    addSectionWithId(sector_id, color, section, sectorName, isShowSectorName) {
      var sizeDonut = this.countSection.length + 1;
      var radiusDonut = 360 / sizeDonut;
      var firstSize = 0;
      if (sizeDonut > 1) {
        for (let j = 0; j < this.countSection.length; j++) {
          var levels = this.options.filter(
            (element) => element.custom.section_id === this.countSection[j]
          );
          for (let k = 0; k < levels.length; k++) {
            levels[k].startAngle = firstSize + 1;
            levels[k].endAngle =
              firstSize + radiusDonut * levels[k].data.length - 1;
          }
          firstSize = (firstSize + radiusDonut * levels[0].data.length) % 360;
        }
      }
      const arraySize = [
        ["43.1818181818%", "44%"],
        ["65.2777777777%", "72%"],
        ["75%", "100%"],
      ];
      for (let i = 0; i < 3; i++) {
        section.degrees[i].levels.sort((a, b) => b.id - a.id);
        this.options.push({
          type: "pie",
          innerSize: arraySize[i][0],
          size: arraySize[i][1],
          showInLegend: isShowSectorName && i < 1,
          custom: {
            degree_id: section.degrees[i].id,
            sector_id: sector_id,
            section_id: section.id,
            level_id: section.degrees[i].levels[0].id,
            name: section.name,
          },
          dataLabels: {
            format: section.name,
            enabled: i === 2,
          },
          startAngle: firstSize + 1,
          endAngle: firstSize + radiusDonut - 1,
          data: [
            {
              y: 1,
              name: sectorName,
              color: color,
              custom: {
                id: section.degrees[i].levels[0].id,
                color: color,
                sector_id: sector_id,
                section_id: section.id,
                degree_id: section.degrees[i].id,
                level_id: section.degrees[i].levels[0].id,
              },
            },
          ],
        });
        for (let k = 1; k < section.degrees[i].levels.length; k++) {
          this.addDonutWithId(
            section.degrees[i].id,
            color,
            section.degrees[i].levels[k],
            section.name,
            sectorName
          );
        }
      }
      this.countSection.push(section.id);
    },
    addDonutWithId(degree_id, color, level, name, sectorName) {
      var elem;
      var levels = this.options.filter(
        (element) => element.custom.degree_id === degree_id
      );
      var isDataLabels = false;
      if (levels.length > 0) {
        var sizeDonut = levels.length + 1;
        var radiusDonut = 25 / sizeDonut;
        var firstSize =
          parseInt(levels[0].size) > this.firstDegree
            ? parseInt(levels[0].size) > this.secondDegree
              ? this.secondDegree
              : this.firstDegree
            : this.radiusWhiteArea;
        var firstSize2 = firstSize + 25;
        for (let i = 0; i < levels.length; i++) {
          levels[i].size = (firstSize + radiusDonut).toString() + "%";
          levels[i].innerSize =
            ((firstSize * 100) / (firstSize + radiusDonut)).toString() + "%";
          firstSize += radiusDonut;
          if (levels[i].dataLabels.enabled) {
            isDataLabels = true;
            levels[i].dataLabels = {
              enabled: false,
            };
          }
        }
        elem = Object.assign({}, levels[levels.length - 1]);
        var newData = [];
        if (isDataLabels) {
          elem.dataLabels = {
            format: name,
            enabled: true,
          };
        }
        elem.size = firstSize2.toString() + "%";
        elem.innerSize =
          (((firstSize2 - radiusDonut) * 100) / firstSize2).toString() + "%";
        newData.push({
          y: 1,
          name: sectorName,
          color: color,
          custom: {
            id: level.id,
            color: color,
            sector_id: elem.custom.sector_id,
            section_id: elem.custom.section_id,
            degree_id: degree_id,
            level_id: level.id,
          },
        });
        elem.custom = Object.assign({}, elem.custom);
        elem.custom.level_id = level.id;
        elem.data = newData;
        elem.showInLegend = false;
      }
      this.options.push(elem);
      // this.$refs.competenceChart.addSeries(elem)
    },
  },
};
</script>

<style scoped>
/* .chart-container{
  width: 100%;
} */
.m-b-20 {
  margin-bottom: 20px;
}
</style>
